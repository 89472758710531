import React from "react";
import AboutImg from "../img/about/logo1.jpg";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { transition1 } from "../transitions";
import { FaMapMarkedAlt } from "react-icons/fa";

const About = () => {
  return (
    <motion.section
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "100%" }}
      transition={transition1}
      className="section"
      style={{ minHeight: "100vh", paddingTop: "140px", overflowY: "auto" }} // Ensures the section is scrollable and accounts for header height
    >
      <div className="container mx-auto h-full relative py-2">
        <div className="flex flex-col lg:flex-row min-h-full items-center justify-center gap-x-24 text-center lg:text-left">
          <div className="flex-1 max-h-96 lg:max-h-max order-2 lg:order-none overflow-hidden">
            <img className="mt-4" src={AboutImg} alt="" />
          </div>
          <motion.div
            initial={{ opacity: 0, y: "-80%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "-80%" }}
            transition={transition1}
            className="flex-1 pt-6 lg:pt-0 lg:w-auto z-10 flex flex-col justify-center items-center lg:items-start"
          >
            <h1 className="h1">My Studio</h1>
            <p className="max-w-sm mb-4">
              Welcome to Nail Boss Studio, located in{" "}
              <a
                href="https://maps.app.goo.gl/KaytdGxRiZ2zZ1me8"
                target="_blank"
                className="text-[#dac489] flex lg:ml-0 md:ml-[100px] sm:ml-[100px]"
              >
                Project 6, Quezon City <FaMapMarkedAlt className="mt-1 ml-2" />
              </a>
              where beauty and elegance meet exceptional nail care. Our studio
              offers a tranquil and inviting atmosphere, perfect for unwinding.
              Whether you're looking for a classic manicure, a trendy nail art
              design, or a rejuvenating pedicure, my experience is here to
              provide personalized and impeccable service.
            </p>
            <Link to={"/portfolio"} className="btn">
              View my work
            </Link>
          </motion.div>
        </div>
      </div>
    </motion.section>
  );
};

export default About;
