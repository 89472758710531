import React from "react";
import Image1 from "../img/portfolio/1.jpg";
import Image2 from "../img/portfolio/2.jpg";
import Image3 from "../img/portfolio/3.jpg";
import Image4 from "../img/portfolio/4.jpg";

import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { transition1 } from "../transitions";
import Testimonials from "../components/Testimonials";

const Portfolio = () => {
  return (
    <motion.section
      initial={{ opacity: 0, y: "100%" }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: "100%" }}
      transition={transition1}
      className="section"
      style={{ minHeight: "100vh", paddingTop: "140px" }} // Ensures the section is scrollable and accounts for header height
    >
      <div className="container mx-auto relative lg:mt-[-80px]">
        {" "}
        {/* Ensure the container can grow */}
        <div className="flex flex-col lg:flex-row items-center justify-start gap-x-24 text-center lg:text-left pt-24 lg:pt-36 pb-8">
          <motion.div
            initial={{ opacity: 0, y: "-80%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "-80%" }}
            transition={transition1}
            className="flex flex-col lg:items-start"
          >
            <h1 className="h1">My Work</h1>
            <p className="mb-12 max-w-sm">
              Visit Nail Boss Studio and let us transform your nails into
              stunning works of art.
            </p>
            <Link to={"/gallery"} className="btn mb-[30px] mx-auto lg:mx-0">
              View More
            </Link>
          </motion.div>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-1 lg:gap-4">
            <div className="max-w-[250px] lg:max-w-[320px] h-[250px] lg:h-[320px] bg-accent overflow-hidden">
              <img
                className="object-cover h-full lg:h-[320px] hover:scale-110 transition-all duration-500"
                src={Image1}
                alt=""
              />
            </div>
            <div className="max-w-[250px] lg:max-w-[320px] h-[250px] lg:h-[320px] bg-accent overflow-hidden">
              <img
                className="object-cover h-full lg:h-[320px] hover:scale-110 transition-all duration-500"
                src={Image2}
                alt=""
              />
            </div>
            <div className="max-w-[250px] lg:max-w-[320px] h-[250px] lg:h-[320px] bg-accent overflow-hidden">
              <img
                className="object-cover h-full lg:h-[320px] hover:scale-110 transition-all duration-500"
                src={Image3}
                alt=""
              />
            </div>
            <div className="max-w-[250px] lg:max-w-[320px] h-[250px] lg:h-[320px] bg-accent overflow-hidden">
              <img
                className="object-cover h-full lg:h-[320px] hover:scale-110 transition-all duration-500"
                src={Image4}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="pt-8 mx-auto">
          {" "}
          {/* Added padding-top for spacing */}
          <h1 className="h1 lg:pl-1 pl-8">
            They're <br></br> Talking...
          </h1>
          {/* Add your reviews segment here */}
          <Testimonials />
          <div></div>
        </div>
      </div>
    </motion.section>
  );
};

export default Portfolio;
