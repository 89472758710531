import React from "react";
import WomanImg from "../img/home/woman.png";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { transition1 } from "../transitions";
import StickyButton from "../components/StickyButton";
import { FaMapMarkedAlt } from "react-icons/fa";

const Home = () => {
  return (
    <motion.section
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={transition1}
      className="section"
      style={{ height: "100vh", overflowY: "auto" }} // Ensures the section is scrollable
    >
      <div className="container mx-auto h-full relative lg:overflow-visible">
        <div className="flex flex-col justify-center min-h-full">
          <motion.div
            initial={{ opacity: 0, y: "-50%" }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: "-50%" }}
            transition={transition1}
            className="w-full pt-36 pb-14 lg:pt-0 lg:pb-0 lg:w-auto z-10 lg:absolute flex flex-col justify-center items-center lg:items-start"
          >
            <h1 className="h1">
              Nail Boss <br /> by Geneva
            </h1>
            <a href="https://maps.app.goo.gl/KaytdGxRiZ2zZ1me8" target="_blank">
              <p className="text-[26px] lg:text-[36px] font-primary mb-4 lg:mb-14 flex hover:text-[#dac489]">
                Project 6, Quezon City <FaMapMarkedAlt className="mt-3 ml-3" />
              </p>
            </a>

            <Link
              to={"/contact"}
              className="btn mb-[30px] outline-2 hover:bg-[#dac489]"
            >
              Book Now
            </Link>
          </motion.div>
          <div className="flex justify-end max-h-96 lg:max-h-max">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={transition1}
              className="relative lg:-right-40 overflow-hidden"
            >
              <motion.img
                whileHover={{ scale: 1.1 }}
                transition={transition1}
                src={WomanImg}
                alt=""
                className="bg-gradient-to-b from-[#dac489] to-white"
              />
            </motion.div>
          </div>
        </div>
      </div>
      <StickyButton facebookPageId="248822661645650" />
    </motion.section>
  );
};

export default Home;
