import React, { useState, useEffect } from "react";
import Socials from "./Socials";
import Logo from "../img/header/logoo.png";
import MobileNav from "./MobileNav";
import { Link } from "react-router-dom";

const Header = () => {
  const [isHidden, setIsHidden] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (currentScrollTop > lastScrollTop) {
        // Downscroll
        setIsHidden(true);
      } else {
        // Upscroll
        setIsHidden(false);
      }
      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [lastScrollTop]);

  return (
    <header
      className={`fixed top-0 w-full px-[30px] lg:px-[100px] z-30 h-[100px] lg:h-[140px] flex items-center bg-transparent transition-all duration-300 ${
        isHidden ? "-top-[140px]" : "top-0"
      }`}
    >
      <div className="flex flex-col lg:flex-row lg:items-center w-full justify-between">
        <Link to={"/"} className="max-w-[200px]">
          <img src={Logo} alt="" className="w-32 h-32" />
        </Link>
        <nav className="hidden xl:flex gap-x-12 font-semibold">
          <Link
            to={"/"}
            className="text-[#696c6d] hover:text-primary transition"
          >
            Home
          </Link>
          <Link
            to={"/about"}
            className="text-[#696c6d] hover:text-primary transition"
          >
            About
          </Link>
          <Link
            to={"/portfolio"}
            className="text-[#696c6d] hover:text-primary transition"
          >
            My Work
          </Link>
          <Link
            to={"/gallery"}
            className="text-[#696c6d] hover:text-primary transition"
          >
            Gallery
          </Link>
          <Link
            to={"/testimonials"}
            className="text-[#696c6d] hover:text-primary transition"
          >
            Testimonials
          </Link>
          <Link
            to={"/contact"}
            className="text-[#696c6d] hover:text-primary transition"
          >
            Contact
          </Link>
        </nav>
      </div>
      <Socials />
      <MobileNav />
    </header>
  );
};

export default Header;
